import React from 'react';
// import JobsItem from './JobsItem'

class JobsList extends React.Component{
  JBL() {

    const people = [
      {title : "test1", company : "Goole", place : "Mexico", work_type : "1 - 2"},
      {title : "test2", company : "Goole", place : "Mexico", work_type : "1 - 2"},
      {title : "test3", company : "Goole", place : "Mexico", work_type : "1 - 2"},
    ];
  
    const list = people.map(item =>
      <JobsItem name={item.title} company={item.company} key={item.title+item.company}/>
    );
    return list;
  }

  render(){
    return(
      <section className="bg-alt" >
        <div className="container">
          <header className="section-header">
            <span>Latest</span>
            <h2>Recent jobs</h2>
            <p>Here's the last five job posted on the website</p>
          </header>

          <div className="row item-blocks-condensed">
          {this.JBL()}

          </div>

          <br /><br />
          <p className="text-center"><a className="btn btn-info" href="job-list.html">Browse all jobs</a></p>
        </div>
      </section>
    );
  }
}

export default JobsList;


class JobsItem extends React.Component{
  render(){
    return (
      <div className="col-xs-12" >
          <a className="item-block" href="job-detail.html">
              <header>
              <img src="assets/img/logo-google.jpg" alt="" />
              <div className="hgroup">
                  <h4>{this.props.name}</h4>
                  <h5>{this.props.company}</h5>
              </div>
              <div className="header-meta">
                  <span className="location">Menlo park, CA</span>
                  <span className="label label-success">Full-time</span>
              </div>
              </header>
          </a>
      </div>
    );
  }
}
