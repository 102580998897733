import React from 'react';

import MainMenu from '../components/nav/MainMenu'
import Header from '../components/vacantions/Header'
import JobsList from '../components/vacantions/JobsList'
import Footer from '../components/main/Footer'


class Vacantions extends React.Component {
  render(){
    return (
      <div>
        <MainMenu selected="vacantion" />
        <Header />
        <JobsList />
        <Footer />
      </div>
    );
  }
  
}

export default Vacantions;


