import React from 'react';
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";

class JobsList extends React.Component{
  JBL() {

    const people = [
      {title : "test1", company : "Goole", place : "Mexico", work_type : "1 - 2"},
      {title : "test2", company : "Goole", place : "Mexico", work_type : "1 - 2"},
      {title : "test3", company : "Goole", place : "Mexico", work_type : "1 - 2"},
    ];
  
    const list = people.map(item =>
      <JobsItem name={item.title} company={item.company} key={item.title+item.company}/>
    );
    return list;
  }

  render(){
    return(
      <main>
      <section className="no-padding-top bg-alt">
        <div className="container">
          <div className="row">

            <div className="col-xs-12">
              <br />
              <h5>We found <strong>357</strong> matches, you're watching <i>10</i> to <i>20</i></h5>
            </div>

            {this.JBL()}

          </div>
          {/* <!-- Page navigation --> */}
          <nav className="text-center">
            <ul className="pagination">
              <li>
                <a href="/#" aria-label="Previous">
                  <i className="ti-angle-left"></i>
                </a>
              </li>
              <li><a href="/#">1</a></li>
              <li className="active"><a href="/#">2</a></li>
              <li><a href="/#">3</a></li>
              <li><a href="/#">4</a></li>
              <li>
                <a href="/#" aria-label="Next">
                  <i className="ti-angle-right"></i>
                </a>
              </li>
            </ul>
          </nav>
          {/* <!-- END Page navigation --> */}

        </div>
      </section>
    </main>
    );
  }
}

export default JobsList;


class JobsItem extends React.Component{
  render(){
    return (
      <div className="col-xs-12">
        <Link className="item-block" to="/vacantion/:id">
          <header>
            <img src="assets/img/logo-facebook.png" alt="" />
            <div className="hgroup">
              <h4>Web Applications Developer</h4>
              <h5>Facebook <span className="label label-danger">Internship</span></h5>
            </div>
            <time dateTime="2016-03-10 20:00">Feb 26, 2016</time>
          </header>

          <div className="item-body">
            <p>Client needs a back-end Java developer who has worked mainly on Java, J2EE, Spring, Web Services, and other Java related technologies.</p>
          </div>

          <footer>
            <ul className="details cols-3">
              <li>
                <i className="fa fa-map-marker"></i>
                <span>Lexington, MA</span>
              </li>

              <li>
                <i className="fa fa-money"></i>
                <span>$130,000 - $150,000 / year</span>
              </li>

              <li>
                <i className="fa fa-certificate"></i>
                <span>Ph.D. or Master</span>
              </li>
            </ul>
          </footer>
        </Link>
      </div>
  );
  }
}
