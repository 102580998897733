import React from 'react';

import MainMenu from '../components/nav/MainMenu'
import Header from '../components/main/Header'
import JobsList from '../components/main/JobsList'
import Footer from '../components/main/Footer'

function App() {
  return (
    <div>
      <MainMenu />
      <Header />
      <JobsList />
      <Footer />
    </div>
  );
}

export default App;


