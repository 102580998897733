import React from 'react';
// import { NavLink } from "react-router-dom";

class Register extends React.Component{
 

 

    componentDidMount() {

        window.$(".body").removeClass('nav-on-header');
        window.$(".body").addClass('login-page')
      }

    render(){
        return(
            <main>
                <div className="login-block">
                    <img src="assets/img/logo.png" alt="" />
                    <h1>Регистрация профиля</h1>

                    <form action="#">

                    <div className="form-group">
                        <div className="input-group">
                        <span className="input-group-addon"><i className="ti-user"></i></span>
                        <input type="text" className="form-control" placeholder="Ваше имя" />
                        </div>
                    </div>
                    
                    <hr className="hr-xs" />

                    <div className="form-group">
                        <div className="input-group">
                        <span className="input-group-addon"><i className="ti-email"></i></span>
                        <input type="text" className="form-control" placeholder="Почта" />
                        </div>
                    </div>
                    
                    <hr className="hr-xs" />

                    <div className="form-group">
                        <div className="input-group">
                        <span className="input-group-addon"><i className="ti-unlock"></i></span>
                        <input type="password" className="form-control" placeholder="Выберите пароль" />
                        </div>
                    </div>

                    <hr className="hr-xs" />

                    <div className="form-group">
                        <div className="input-group">
                        <span className="input-group-addon"><i className="ti-unlock"></i></span>
                        <input type="password" className="form-control" placeholder="Введите пароль повторно" />
                        </div>
                    </div>

                    <button className="btn btn-primary btn-block" type="submit">Sign up</button>

                    {/* <div className="login-footer">
                        <h6>Or register with</h6>
                        <ul className="social-icons">
                        <li><a className="facebook" href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a className="linkedin" href="#"><i className="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div> */}

                    </form>
                </div>

                <div className="login-links">
                    <p className="text-center">Already have an account? <a className="txt-brand" href="user-login.html">Login</a></p>
                </div>

            </main>
        );
    }
}

export default Register;