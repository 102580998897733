import React from 'react';
import { NavLink } from "react-router-dom";



class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            email:"",
            password:"",
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value
        this.setState({
            [name]: value
        });
    }

    componentDidMount(){
        window.$(".body").removeClass('nav-on-header');
        window.$(".body").addClass('login-page');
        window.$('.loader').hide()
        
    }

    handleSubmit(event) {
        window.$('.loader').show()
        window.$('.loginForm').hide()

        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email:"email", password:"password" })
        };
      
        fetch('https://jb.ehost.tj/auth', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.props.history.push('/')
            })
            .catch(error =>{
                window.$('.loader').hide()
                window.$('.loginForm').show()
            })

        event.preventDefault();
      }
    

    render(){
        return(
            <main>
            <div className="login-block">
                <img src="assets/img/logo.png" alt="" />
                <div className="loader">
                    <img src="/assets/img/loader.gif"  alt=""/>
                </div>
                <div className="loginForm">
                <h1>Войдите в профиль</h1>

                    <form onSubmit={this.handleSubmit}>

                    <div className="form-group">
                        <div className="input-group">
                        <span className="input-group-addon"><i className="ti-email"></i></span>
                        <input type="text" name="email" className="form-control" placeholder="Почта" onChange={this.handleChange}/>
                        </div>
                    </div>

                    <hr className="hr-xs" />

                    <div className="form-group">
                        <div className="input-group">
                        <span className="input-group-addon"><i className="ti-unlock"></i></span>
                        <input type="password" name="password" className="form-control" placeholder="Пароль" onChange={this.handleChange}/>
                        </div>
                    </div>


                    <input className="btn btn-primary btn-block" type="submit" value="Отправить" />



                    {/* <div className="login-footer">
                        <h6>Or login with</h6>
                        <ul className="social-icons">
                            <li><a className="facebook" href="/#"><i className="fa fa-facebook"></i></a></li>
                            <li><a className="twitter" href="/#"><i className="fa fa-twitter"></i></a></li>
                            <li><a className="linkedin" href="/#"><i className="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div> */}

                    </form>
                </div>
                
            </div>

            <div className="login-links">
                <NavLink className="pull-left" to="reset-password">Забыли пароь?</NavLink>
                <a className="pull-right" href="user-register.html">Зарегистрировать профиль</a>
            </div>
            </main>
        );
    }
}

export default Login;