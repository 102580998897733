import React from "react";
import Cookies from 'js-cookie';
import { NavLink } from "react-router-dom";

class MainMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            auth :{
                username: Cookies.get('username'),
                status : Cookies.get('auth')
            }
        }        
    }

    componentDidMount(){
        window.$(".selectpicker").selectpicker()
        window.$(".body").addClass('nav-on-header');
        window.$(".body").removeClass('login-page');

    }

    
    profile() {
        if(this.state.auth.status === true){
            return (
                <ul className="dropdown-menu dropdown-menu-right">
                                <li><a href="user-login.html">Моя анкета</a></li>
                                <li><a href="user-register.html">Мои вакансии</a></li>
                                <li><a href="user-register.html">Настройки</a></li>
                                
                                <li className="logout"><a href="user-register.html">Выход</a></li>
                            </ul>
            );
        }

        return (
                <ul className="dropdown-menu dropdown-menu-right">
                    <li><NavLink to="/login">Авторизация</NavLink></li>
                    <li><NavLink to="/register">Регистрация</NavLink></li>
                </ul>
        );
    }


    render(){
        return (
            <nav className="navbar">
                <div className="container">
                    <div className="pull-left">
                        
                        <a className="navbar-toggle" href="/#" data-toggle="offcanvas"><i className="ti-menu"></i></a>
                        <div className="logo-wrapper">
                            <a className="logo" href="index.html"><img src="/assets/img/logo.png" alt="logo" /></a>
                            <a className="logo-alt" href="index.html"><img src="/assets/img/logo-alt.png" alt="logo-alt" /></a>
                        </div>
                    </div>
                
                    <div className="pull-right ">
                        <div className="dropdown user-account">
                            <a className="dropdown-toggle profile" href="/#" data-toggle="dropdown">
                                Профиль
                            </a>
                            {this.profile()}
                        </div>
                    </div>
                
                    <ul className="nav-menu">
                        <li>
                            <NavLink exact activeClassName="active" to="/">Главная</NavLink>                            
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/vacantions" >Вакансии</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/resume">Резюме</NavLink>
                           
                        </li>
                        <li>
                            <a href="/#">Компании</a>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}


export default MainMenu;