import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import { createBrowserHistory } from "history";

import Main from './pages/Main';
import Vacantions from './pages/Vacantions';
import Vacantion from './pages/Vacantion';
import Login from './pages/Login';
import Register from './pages/Register';

import * as serviceWorker from './serviceWorker';


const history = createBrowserHistory();

ReactDOM.render(
    
    <Router history={history}>
      <Switch>
        <Route exact path='/' component={Main}/>
        <Route path='/vacantions' component={Vacantions}/>
        <Route path='/vacantion/:id' component={Vacantion}/>
        <Route path='/login/' component={Login}/>
        <Route path='/register/' component={Register}/>
      </Switch>
    </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
