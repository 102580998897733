import React from 'react';
// import Select from 'react-select'

class Header extends React.Component{

    
    render(){

      // const options = [
      //   { value: 'chocolate', label: 'Chocolate' },
      //   { value: 'strawberry', label: 'Strawberry' },
      //   { value: 'vanilla', label: 'Vanilla' }
      // ]

        return(
            <header className="page-header bg-img">
            <div className="container page-name">
              <h1 className="text-center">Browse jobs</h1>
              <p className="lead text-center">Use following search box to find jobs that fits you better</p>
            </div>
      
            <div className="container">
              <form action="#">
      
                <div className="row">
                  <div className="form-group col-xs-12 col-sm-4">
                    <input type="text" className="form-control" placeholder="Keyword: job title, skills, or company" />
                  </div>
      
                  <div className="form-group col-xs-12 col-sm-4">
                    <input type="text" className="form-control" placeholder="Location: city, state or zip" />
                  </div>
      
                  <div className="form-group col-xs-12 col-sm-4">
                    <select className="form-control selectpicker"  multiple={true}>
                      <option value="1">All categories</option>
                      <option value="2">Developer</option>
                      <option value="3">Designer</option>
                      <option value="4">Customer service</option>
                      <option value="5">Finance</option>
                      <option value="6">Healthcare</option>
                      <option value="7">Sale</option>
                      <option value="8">Marketing</option>
                      <option value="9">Information technology</option>
                      <option value="10">Others</option>
                    </select>
                   
                    
                  </div>

                  
      
                  <div className="form-group col-xs-12 col-sm-4">
                    <h6>Contract</h6>
                    <div className="checkall-group">
                      <div className="checkbox">
                        <input type="checkbox" id="contract1" name="contract" checked={true} onChange={this.handleClick}/>
                        <label htmlFor="contract1">All types</label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="contract2" name="contract" onChange={this.handleClick}/>
                        <label htmlFor="contract2">Full-time <small>(354)</small></label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="contract3" name="contract" onChange={this.handleClick}/>
                        <label htmlFor="contract3">Part-time <small>(284)</small></label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="contract4" name="contract" onChange={this.handleClick}/>
                        <label htmlFor="contract4">Internship <small>(169)</small></label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="contract5" name="contract" onChange={this.handleClick}/>
                        <label htmlFor="contract5">Freelance <small>(480)</small></label>
                      </div>
                    </div>
                  </div>
      
      
                  <div className="form-group col-xs-12 col-sm-4">
                    <h6>Hourly rate</h6>
                    <div className="checkall-group">
                      <div className="checkbox">
                        <input type="checkbox" id="rate1" name="rate" checked={true} onChange={this.handleClick}/>
                        <label htmlFor="rate1">All rates</label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="rate2" name="rate" onChange={this.handleClick}/>
                        <label htmlFor="rate2">$0 - $50 <small>(364)</small></label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="rate3" name="rate" onChange={this.handleClick}/>
                        <label htmlFor="rate3">$50 - $100 <small>(684)</small></label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="rate4" name="rate" onChange={this.handleClick}/>
                        <label htmlFor="rate4">$100 - $200 <small>(195)</small></label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="rate5" name="rate" onChange={this.handleClick}/>
                        <label htmlFor="rate5">$200+ <small>(39)</small></label>
                      </div>
                    </div>
                  </div>
      
      
                  <div className="form-group col-xs-12 col-sm-4">
                    <h6>Academic degree</h6>
                    <div className="checkall-group">
                      <div className="checkbox">
                        <input type="checkbox" id="degree1" name="degree" checked={true} onChange={this.handleClick}/>
                        <label htmlFor="degree1">All degrees</label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="degree2" name="degree" onChange={this.handleClick}/>
                        <label htmlFor="degree2">Associate degree <small>(216)</small></label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="degree3" name="degree" onChange={this.handleClick}/>
                        <label htmlFor="degree3">Bachelor's degree <small>(569)</small></label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="degree4" name="degree" onChange={this.handleClick}/>
                        <label htmlFor="degree4">Master's degree <small>(439)</small></label>
                      </div>
      
                      <div className="checkbox">
                        <input type="checkbox" id="degree5" name="degree" onChange={this.handleClick}/>
                        <label htmlFor="degree5">Doctoral degree <small>(84)</small></label>
                      </div>
                    </div>
                  </div>
      
                </div>
      
                <div className="button-group">
                  <div className="action-buttons">
                    <button className="btn btn-primary">Apply filter</button>
                  </div>
                </div>
      
              </form>
      
            </div>
      
          </header>
        );
    }

    handleClick(){
      console.log('eeee')
    }
}

export default Header;